import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
   
    {
        id: 1,
        label: 'Tableau de board',
        icon: 'bx-home-circle',
        link: '/dashboard',
        access: 'Dashboard',
         
       
    },
     
    
  

    {
        id: 3,
        label: 'Signaletiques',
        icon: 'bx bx-user-circle',
        access: 'Signaletique',
        subItems: [
            {
                id: 1,
                label: 'Repertoire des clients',
                link: '/gestion-client/client',
                parentId: 3,
                privilege:'lister_client',
            },
            {
                id: 2,
                label: 'Repertoire fournisseurs',
                link: '/gestion-fournisseur/fournisseur',
                parentId: 4,
                privilege:'lister_fournisseur',
            },
            
             
            
        ]
    },
   
   
    {
        id: 4,
        label: 'Gestion de produit',
        icon: 'bx bx-archive',
        access: 'Gestion produit',
        subItems: [
            {
                id: 1,
                label: 'Categorie',
                link: '/gestion-produit/categorie',
                parentId: 4,
                privilege:'lister_categorie',
            },
            {
                id: 2,
                label: 'Produit',
                link: '/gestion-produit/produit',
                parentId: 4,
                privilege:'lister_produit',
            },
            
             
            
        ]
    },
    {
        id: 29,
        label: 'Gestion de service',
        icon: 'bx-envelope',
        access: 'Gestion service',
        subItems: [
            {
                id: 30,
                label: 'Categorie',
                link: '/gestion-service/categorie',
                parentId: 29,
                privilege:'lister_service',
            },
            {
                id: 31,
                label: 'Service',
                link: '/gestion-service/service',
                parentId: 29,
                privilege:'lister_categorie_service',
            }
           
        ]
    },
    {
        id: 36,
        label: 'Gestion de RDV',
        icon: 'bx bx-calendar-plus',
        access: 'Gestion rdv',
        subItems: [
            {
                id: 37,
                label: 'Rendez-vous',
                link: '/gestion-rendez-vous/rendez-vous',
                parentId: 36,
                privilege:'lister_rdv',
            },

            {
                id: 37,
                label: 'Consultation des RDV',
                link: '/consultation/rendez-vous',
                parentId: 36,
                privilege:'consultation_rdv',
            },
            
        ]
    },

    
    {
        id: 36,
        label: 'Approvisonnement',
        icon: 'bx bx-data',
        access: 'Approvisionnement', 
        subItems: [
            {
                id: 37,
                label: 'Aprovisonnement',
                link: '/approvisionnement/approv',
                parentId: 36,
                privilege:'creer_approvisonnement',
            },


 
           
        ]
    },

    {
        id: 36,
        label: 'Facturation',
        icon: 'bx bx-cart-alt',
        access: 'Facturation',
        subItems: [
            {
                id: 37,
                label: 'Facturation service',
                link: '/facturation/facturation-service',
                parentId: 36,
                privilege:'facturation_service',
            },

            
            {
                id: 37,
                label: 'Vente',
                link: '/facturation/vente',
                parentId: 36,
                privilege:'facturation_vente',
            },
           
        ]
    },

    {
        id: 36,
        label: 'Consultation',
        icon: 'bx bx-search-alt-2',
        access: 'Consultation',
        subItems: [
             


            {
                id: 37,
                label: 'Consultation factures service',
                link: '/consultation/liste-facturation-service',
                parentId: 36,
                privilege:'consultation_facturation_service', 
            },

            {
                id: 37,
                label: 'Consultation des approvisionnements',
                link: '/consultation/consultation-approvisonnement',
                parentId: 36,
                privilege:'consultation_appro',
            },

            {
                id: 37,
                label: 'Consultation des ventes',
                link: '/consultation/liste-vente',
                parentId: 36,
                privilege:'consultation_facturation_vente',
            },


           

            {
                id: 37,
                label: 'Consultation des produits',
                link: '/consultation/produit',
                parentId: 36,
                privilege:'consultation_produit',
            },


            
             
           
        ]
    },
    
    {
        id: 36,
        label: 'Parametrages',
        icon: 'bx bx-cog',
        access: 'Parametrage',
        subItems: [
            {
                id: 37,
                label: 'Devise',
                link: '/parametrage/devise',
                parentId: 36,
                privilege:'lister_Devise',
            },
            {
                id: 37,
                label: 'Mode de paiement',
                link: '/parametrage/modepaiement',
                parentId: 36,
                privilege:'lister_modepaiement',
            },

            {
                id: 37,
                label: 'Rôle utilisateur',
                link: '/parametrage/role',
                parentId: 36,
                privilege:'lister_role',
            },

            {
                id: 37,
                label: 'Utilisateur',
                link: '/parametrage/utilisateur',
                parentId: 36,
                privilege:'lister_user',
            },
           
           
        ]
    },
   
  
];

