import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/auth.models';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;


    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
     }

     public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }
  httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
         
    })
};
    /**
     * Returns the current user
     */
    // public currentUser(): User {
    //     return getFirebaseBackend().getAuthenticatedUser();
    // }https://api.mlbeautybar.net/api/

     
    //environment.apiUrl  +

    login( data: any ):Observable<any> {
       // console.log(data)
        return this.http.post(environment.apiUrl  +'login', data);
      }

     
    register( data: any ):Observable<any> {
        return this.http.post( environment.apiUrl  + 'register', data);
    }

    /**
     * Reset password
     * 
     */
    // resetPassword(email: string) {
    //     return getFirebaseBackend().forgetPassword(email).then((response: any) => {
    //         const message = response.data;
    //         return message;
    //     });
    // }

    /**
     * Logout the user
     */
    logout() {
        // logout the user
       // getFirebaseBackend().logout();
    }
}

