export class AppUtilities{
    // cette methode sert à controler les access et privileges des utilisateurs sur les modules ...
        public static controleAccess(module:string, privilege:string=""){
        
               const moduleFormated:any[]=[]
               const listDroit :any=JSON.parse(sessionStorage.getItem('modules'))
               
             
               listDroit.forEach((value:any)=>{
                 moduleFormated.push((value))
               })
              
               if(privilege=="")
                  return moduleFormated.some((value:any)=> value.module==module)
               else 
                 return moduleFormated.some((value:any)=> (value.module.toUpperCase()==module.toUpperCase() && value.code_droit.toUpperCase()==privilege.toUpperCase()  ))
             
               }
       }