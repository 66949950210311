<header id="page-topbar" >
  <div class="navbar-header" >
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box" style="background-color: #515997;" >
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
             
            <img src="assets/images/estockmin.png" alt="" height="32">
          </span>
          <span class="logo-lg">
            <img src="assets/images/Logo-02.png" alt="" height="80">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/estockmin.png" alt="" height="32">
          </span>
          <span class="logo-lg">
            <img src="assets/images/Logo-02.png" alt="" height="80">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

   

      
    </div>

    <div class="d-flex">
      
 

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>
 

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user" src="assets/images/users/logouser.png" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">{{uName | lowercase}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" routerLink="/contacts/profile"><i class="bx bx-user font-size-16 align-middle me-1"></i>
           Mon profil</a>
          
          <div class="dropdown-divider"></div>
         <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
           Deconnexion</a> 
        </div>
      </div>
 

    </div>
  </div>
</header>